@import "./variables.scss";
@import "./fonts.scss";

@import "./components/button.scss";
@import "./components/slider.scss";
@import "./components/activity_modal.scss";

@import "./pages/home.scss";

.card-body > div:first-child {
  width: calc(100% - 56px);
}
.slick-arrow {
  &::before {
    content: none;
  }
  i {
    font-size: 20px;
    line-height: 20px;
    color: white;
    top: 0px;
  }
}
.health-list {
  background-color: #f4f4f4;
  padding: 24px;
  gap: 16px;
  border-radius: 12px;
  &:first-child {
    margin-right: 0px;
    height: 100%;
    // width: calc(100% - 10px);
  }
}
.button {
  text-shadow: none !important;
}
