button.save-activity {
  width: 100%;
}
.modal-header,
.modal-footer {
  border: 0px !important;
}
.modal-header {
  padding-bottom: 0px;
  .activity_titles.modal-title.h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #000;
    padding-bottom: 0px;
  }
  button {
    font-size: 12px;
    font-weight: 900;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
  }
}
.modal-body {
  label.form-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #1d1f1d;
    margin-bottom: 4px;
  }
  padding-bottom: 0px;
}
.modal-footer {
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  .row {
    width: 100%;
    .col {
      padding: 0px;
      padding-right: 4px;
    }
    button {
      width: 100%;
    }
  }
}
