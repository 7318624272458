.color-primary {
  color: $color-primary;
}
.color-border {
  color: $color-border;
}
.color-text-primary {
  color: $color-typography-text-primary;
}
.color-text-secondary {
  color: $color-typography-text-secondary;
}
.color-secondary-cream {
  color: $color-secondary-cream;
}
.color-pure-white {
  color: $color-pure-white;
}

.typography {
  font-family: "Poppins";
  &.nav-label {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 87.5% */
    letter-spacing: -0.32px;
  }
  &.mobile-body-small {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  &.desktop-body-small {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  &.body-medium-regular {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
  &.body-small-regular {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
  &.body-small-bold {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  &.body-xsmall-regular {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  &.display-large {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1.2px;
  }
  &.headings-large {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  &.headings-xxlarge {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  &.headings-xlarge {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  &.headings-xxlarge {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  &.headings-xsmall {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
  &.headings-small {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }
}
.button {
  // font-family: "Poppins";
  &.btn-xsmall {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.24px;
  }
  &.btn-small {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
}
