// Color Vairaibles
$color-primary: #6a9874;
$color-secondary-blue: #728ca0;
$color-secondary-slate: #444444;

$color-secondary-cream: #f4f0ee;
$color-typography-text-primary: #1d1f1d;
$color-typography-text-secondary: #4e524f;
$color-border: #e6ebe7;
$color-border-active: #d6e3d9;

$color-pure-white: #ffffff;

//border
$rounded-corner-full: 99px;
$rounded-corder-small: 4px;
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1240px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
