.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  // color
  &.btnn-primary {
    color: $color-pure-white;
    background: #6a9874e5; //primary-90%
    &:hover {
      background: $color-primary;
    }
  }
  &.btnn-secondary {
    color: $color-typography-text-secondary;
    border: 1px solid $color-border;
    background: transparent;
    &:hover {
      color: $color-primary;
      border: 1px solid $color-border-active;
    }
  }

  //type
  &.btnn-normal {
    border-radius: $rounded-corder-small;
  }
  &.btnn-round,
  &.btnn-circle {
    border-radius: $rounded-corner-full;
  }
  //size
  &.btnn-xsmall {
    padding: 6px 8px;
  }
  &.btnn-small {
    padding: 8px 12px;
  }

  //   direction
  &.btnn-left {
    flex-direction: row-reverse;
  }
}
