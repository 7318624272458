.home-slider-wrapper {
  background-color: $color-primary;
}

@media (max-width: 576px) {
  .card {
    max-width: calc(100% - 20px) !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    z-index: 100 !important;
  }
  .slick-prev {
    left: -9px !important;
    z-index: 101 !important;
  }
  .slick-next {
    right: -13px !important;
    z-index: 101 !important;
  }
}
.button {
  text-transform: none !important;
}
.card-op .button:hover {
  background: none;
  color: #1d1f1d;
}
.card-body p.typography {
  padding-bottom: 30px;
}
@media (max-width: 770px) {
  .target-detail-body .target-detail-left {
    width: 100%;
    margin-top: 30px;
  }
}
.MobileContent {
  display: block !important;
}
.progress-stacked-line {
  z-index: 4 !important;
}
.progress-stacked {
  z-index: 2 !important;
}
.side-panel-wrap {
  z-index: 8888;
}
.target-detail-body .target-detail-right {
  overflow-x: hidden !important;
}
@media (max-width: 576px) {
  .card {
    max-width: calc(100% - 20px) !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .slick-prev {
    left: -9px !important;
  }
  .slick-next {
    right: -13px !important;
  }
}
